import createPersistedState from "vuex-persistedstate";

import Vue from 'vue'
import Vuex from 'vuex'

import license from './modules/license'
import user from './modules/user'
import group from './modules/group'
import job from './modules/job'


Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        license: license,
        user: user,
        group: group,
        job: job
    },
    plugins: [createPersistedState({
        paths: [
            'user.filter',
            'user.grid',
            'group.filter',
            'group.grid'
        ]
    })],
})