import Vue from 'vue'
import _ from 'lodash'
export default {
    secondsToTime(s, asObject = false) {
        const $_s = _.isNil(s) ? 0 : s;
        const time = Vue.prototype.$moment($_s, 'X').toISOString().substr(11, 8);
        if(asObject === true)
            return _.zipObject(['hour', 'minute', 'seconds'], _.map(_.split(time, ':'), (v) => _.parseInt(v)));

        return time;
    },
    timeToSeconds(time) {
        const arTime = _.split(_.padEnd(time, 8, ':00'), ':');
        return _.sum(_.map(arTime, (v,k) => {
            return _.parseInt(v) * [3600, 60, 1][k];
        }));
    },
    leadZero(value) {
        return _.parseInt(value) <= 9 ? _.join(['0', value], '') : value;
    }
}