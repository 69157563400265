<template>
    <v-card class="elevation-12">
        <!-- Body -->
        <v-card-text>
            <v-form>
                <div v-if="success">
                    <v-alert type="success">
                        <p>Пароль успешно изменен!</p>
                        <p>Воспользуйтесь формой авторизации для входа в учетную запись с новым паролем.</p>
                    </v-alert>
                </div>
                <div v-else>
                    <v-alert
                      v-if="!_.isNil(summaryError)"
                      dense
                      type="error"
                    >
                        {{ summaryError }}
                    </v-alert>

                    <div v-if="form.type === 'send'">
                        <v-text-field 
                            v-model="$v.form.login.$model"
                            :error-messages="getErrors('form.login')"
                            label="Логин" 
                            name="login" 
                            prepend-icon="mdi-account" 
                            type="text" 
                            placeholder=" "
                            autofocus="autofocus" 
                            color="purple darken-2"
                        ></v-text-field>
                    </div>

                    <div v-else-if="form.type === 'validate'">
                        <v-alert type="info">
                            На указанный e-mail отправлено письмо с контрольной строкой, необходимой для восстановления пароля.
                        </v-alert>

                        <v-text-field 
                            v-model="$v.form.checkword.$model"
                            :error-messages="getErrors('form.checkword')"
                            label="Контрольная строка" 
                            name="login" 
                            prepend-icon="mdi-account" 
                            type="text" 
                            placeholder=" "
                            autofocus="autofocus" 
                            color="purple darken-2"
                        ></v-text-field>
                    </div>
                    <div v-else-if="form.type === 'change'">
                        <v-text-field 
                            v-model="$v.form.password.$model"
                            :error-messages="getErrors('form.password')"
                            id="password" 
                            label="Пароль" 
                            name="password" 
                            prepend-icon="mdi-lock" 
                            type="password" 
                            placeholder=" " 
                            color="purple darken-2"
                        ></v-text-field>

                        <v-text-field 
                            v-model="$v.form.password_confirm.$model"
                            :error-messages="getErrors('form.password_confirm')"
                            id="password" 
                            label="Подтверждение пароля" 
                            name="password_confirm" 
                            prepend-icon="mdi-lock" 
                            type="password" 
                            placeholder=" " 
                            color="purple darken-2"
                        ></v-text-field>
                    </div>
                </div>
            </v-form>
        </v-card-text>
        <!-- Footer -->
        <v-card-actions class="px-0" v-if="!success">
            <v-row no-gutters justify="center" align="center">
                <v-btn 
                    :elevation="0" 
                    color="purple darken-2"
                    class="white--text"
                    :disabled="$v.form.$invalid"
                    :loading="waiting"
                    @click.prevent.stop="submit"
                >
                    Восстановить
                </v-btn>
            </v-row>    
        </v-card-actions>
    </v-card>
</template>
<script>
import { errorMixin } from '@/mixins/formMixin'
import { validationMixin } from 'vuelidate'
import { required, email, minLength, sameAs } from 'vuelidate/lib/validators'

export default {
    name: 'ChangePasswordForm',
    mixins: [validationMixin, errorMixin],
    props: {},
    data() {
        return {
            success: false,
            form: {
                type: 'send',
                login: '',
                checkword: '',
                password: '',
                password_confirm: ''
            },
            error: null,
            waiting: false
        };
    },
    computed: {},
    validations() {
        const rules = {
            send: {
                login: {
                    required,
                    email
                }
            },
            validate: {
                checkword: {
                    required,
                    minLength: minLength(32)
                }
            },
            change: {
                password: {
                    required,
                    minLength: minLength(8)
                },
                password_confirm: {
                    required,
                    minLength: minLength(8),
                    sameAsPassword: sameAs('password')
                }
            }
        }

        return {
            form: rules[this.form.type]
        }
    },
    methods: {
        validate() {
            this.$v.$touch();
            return !this.$v.$invalid;
        },
        async submit() {
            if (this.validate()) {
                this.waiting = true;

                const { success, error } = await this.$store.dispatch('user/changePassword', this.form);

                this.error = error;

                if (success)
                {
                    switch(this.form.type)
                    {
                        case 'send':
                            this.form.type = 'validate';
                        break;

                        case 'validate':
                            this.form.type = 'change';
                        break;

                        case 'change':
                            this.success = true;
                        break;
                    }
                }
                else
                    this.serverErrors = error;

                this.waiting = false;

                return;
            }
        }
    }

}
</script>