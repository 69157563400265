<template>
    <div>
        <p>Приветствуем Вас в Административной части системы СдамВПР!</p>
        <p>Здесь Вы можете</p>
        <ul>
            <li>управлять подписками для школы;</li>
            <li>создавать классы и наполнять их учащимися;</li>
            <li>создавать учителей и устанавливать классы, которым учитель сможет назначать работы.</li>
        </ul>
        <p>&nbsp;</p>
        <p>В разделе <router-link to="/license">"Лицензии"</router-link> можно просматривать текущие лицензии и добавлять новые лицензии нажатием кнопки "Активировать ключ".</p>
        <p>В разделе <router-link to="/group">"Классы"</router-link> можно создавать новые классы и наполнять их учениками и учителями.</p>
        <p>В разделе <router-link to="/user">"Пользователи"</router-link> можно создавать пользователей «учитель» и «ученик». Пользователей можно создавать <router-link to="/user/upload">массово</router-link> с помощью загрузки <a target="_blank" href="/download/user_upload_template.xlsx">таблицы</a> со списком, либо по одному пользователю нажатием <router-link to="/user/create">"Создать аккаунт"</router-link>.</p>
    </div>
</template>
<script>
export default {
    data() {
        return {}
    }
}
</script>
<style lang="scss" scoped></style>