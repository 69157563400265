import Vue from 'vue'
import ApiHelper from '@/helpers/ApiHelper'
import TimeHelper from '@/helpers/TimeHelper'
import {
    TASK_SERVER,
    TASK_SERVER_API_KEY
} from '@/servers'

const MINUTES_45 = 60 * 45;
const MINUTES_60 = 60 * 60;
const MINUTES_90 = 60 * 90;

export default {
    namespaced: true,
    state: {
        lists: {
            demo: {
                items: [],
                lastUpdate: 0
            }
        }
    }, 
    mutations: {
        SET_DEMO(state, data) {
            Vue.set(state.lists.demo, 'items', data);
        }
    },
    actions: {
        async fetchDemo({commit, getters}) {
            const { data } = await ApiHelper.get(`${TASK_SERVER}/api/job/demo`, null, {
                api_key: TASK_SERVER_API_KEY
            });
            const items = _.map(_.get(data, 'items', []), (o, k) => {
                const time = getters.duration(o.subject, o.grade, _.parseInt(o.is_final) > 0);
                const obTime = TimeHelper.secondsToTime(time, true);
                const timeFormatted = _.join(_.compact([
                    obTime.hour > 0 ? `${obTime.hour} ч.` : null,
                    obTime.minute > 0 ? `${obTime.minute} мин.` : null
                ]), ' ');
                return {
                    id: `demo_${k}_${o.grade}`,
                    time: time,
                    time_formatted: timeFormatted,
                    job_name: "Подготовка к ВПР",
                    job_subname: `${o.subject}, ${o.grade} класс${_.parseInt(o.is_final) > 0 ? ', итоговая работа' : ''}`,
                    subject: o.subject,
                    grade: _.parseInt(o.grade),
                    is_final: _.parseInt(o.is_final) > 0 ? 1 : 0,
                    job_tasks_count: o.task_count,
                    job_tasks: [],
                    result: {
                        started_at: null
                    }
                }
            });
            commit('SET_DEMO', items);
        },
        async fetchDemoTasks(context, data) {
            return ApiHelper.get(`${TASK_SERVER}/api/task`, data, {
                api_key: TASK_SERVER_API_KEY
            });
        }
    },
    getters: {
        findDemoBySubjectAndGrade: (state) => (subject, grade, is_final) => {
            return _.find(state.lists.demo.items, {
                subject: _.replace(subject, '-', ' '),
                grade: _.parseInt(grade),
                is_final: _.parseInt(is_final)
            });
        },
        duration: () => (subject, grade, is_final) => {
            const subjectsDuration = {
                'Биология': {
                    regular: {
                        5: MINUTES_45,
                        6: MINUTES_45,
                        7: MINUTES_60
                    }
                },
                'География': {
                    regular: {
                        6: MINUTES_60
                    }
                },
                'История': {
                    regular: {
                        5: MINUTES_45
                    }
                },
                'Математика': {
                    regular: {
                        4: MINUTES_45,
                        5: MINUTES_60,
                        6: MINUTES_60,
                        7: MINUTES_90
                    }
                },
                'Обществознание': {
                    regular: {
                        7: MINUTES_45
                    }
                },
                'Окружающий мир': {
                    regular: {
                        4: MINUTES_45
                    }
                },
                'Русский язык': {
                    regular: {
                        4: MINUTES_90,
                        6: MINUTES_90,
                        7: MINUTES_90
                    },
                    final: {
                        4: MINUTES_90
                    }
                },
                'Химия': {
                    regular: {
                        8: MINUTES_90
                    }
                }
            };

            return _.get(subjectsDuration, `${subject}.${is_final ? 'final' : 'regular'}.${grade}`, MINUTES_60);
        }
    }
}