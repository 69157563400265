import ApiHelper from '@/helpers/ApiHelper.js'
// import Crud from '@/store/classes/crud'
// const crud = new Crud({
//     module: 'license'
// })
const md5 = require('js-md5')

export default {
    namespaced: true,
    state: {
        items: [],
        loading: false
    },
    mutations: {
        setItems (state, value) {
            state.items = value;
        },
        setLoading (state, value) {
            state.loading = value;
        }
    },
    actions: {
        async list(context) {
            if (context.state.loading) { return; }
            
            context.commit('setLoading', true);

            const { data, success, error } = await ApiHelper.get(process.env.VUE_APP_CODE_SERVER_HOST + '/api/license', {
                hash: md5('owner_' + _.get(context, 'rootState.user.profile.id', '0')),
                user_id: _.get(context, 'rootState.user.profile.id', '0')
            }, {
                api_key: process.env.VUE_APP_CODE_SERVER_API_KEY
            });

            if(success)
                context.commit('setItems', _.get(data, 'items', []));
            else
                console.error(error);
            
            context.commit('setLoading', false);
        },
        async subjects() {
            return ApiHelper.get(process.env.VUE_APP_CODE_SERVER_HOST + '/api/license-type/subjects', {}, {
                api_key: process.env.VUE_APP_CODE_SERVER_API_KEY
            });
        },
        // list: crud.actions.list,
        async checkCode(context, data) {
            return ApiHelper.get(process.env.VUE_APP_CODE_SERVER_HOST + '/api/code/check', data, {
                api_key: process.env.VUE_APP_CODE_SERVER_API_KEY
            });
        },
        async activate(context, params) {
            return ApiHelper.post(process.env.VUE_APP_CODE_SERVER_HOST + '/api/code/activate', {
                owner_id: _.get(context, 'rootState.user.profile.id', '0'),
                owner_login: _.get(context, 'rootState.user.profile.login', ''),
                ...params.activation
            }, 
            {
                hash: params.hash
            },
            {
                api_key: process.env.VUE_APP_CODE_SERVER_API_KEY
            });
        }
    }
}