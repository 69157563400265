import ApiHelper from '@/helpers/ApiHelper.js'
import localForage from "localforage";
localForage.config({
    driver: localForage.INDEXEDDB,
    name: `${process.env.VUE_APP_CODENAME}_app_storage`
});

export default class {
    constructor() {
        this.state = {
            access_token: null,
            profile: null
        };
        this.mutations = {
            LOGIN(state, data) {
                state.access_token = data.access_token;
                state.profile = data.profile;

                localForage.setItem('access_token', state.access_token); 
                localForage.setItem('profile', state.profile);    
            },
            LOGOUT(state) {
                state.access_token = null;
                state.profile = null;
            }
        };
        this.actions = {
            async setSession({commit}, data) {
                commit('LOGIN', {
                    access_token: _.get(data, 'access_token', null),
                    profile: _.get(data, 'profile', null)
                });
            },
            async restoreSession({ dispatch }) {
                const access_token = await localForage.getItem('access_token');
                const profile = await localForage.getItem('profile');

                await dispatch('setSession', {
                    access_token,
                    profile
                });

                return true;
            },
            async login(context, data) {
                return ApiHelper.post('/api/login', data);
            },
            async logout({ commit }) {
                await localForage.removeItem('profile');
                await localForage.removeItem('access_token');

                commit('LOGOUT');

                return true;
            },
            async register(context, data) {
                return ApiHelper.post('/api/register', data);
            },
            async changePassword(context, data) {
                return ApiHelper.post(`/api/change-password`, data);
            }
        };
        this.getters = {
            isAuthorized: (state) => {
                return !_.isNil(_.get(state, 'profile.id', null));
            }
        }
    }
}