<template>
    <v-row align="center" justify="center" dark>
        <v-col cols="12" sm="8" md="6">
            <v-tabs background-color="purple darken-2" dark>
                <!-- Tabs -->
                <v-tab>Войти</v-tab>
                <v-tab>Создать аккаунт</v-tab>
                <v-spacer />
                <v-tab>Забыли пароль?</v-tab>
                <!-- Tab items -->
                <v-tab-item>
                    <login-form 
                        @success="onSuccess" 
                        @back="onBack" 
                    />
                </v-tab-item>
                <v-tab-item>
                    <register-form 
                        new-account 
                        @success="onSuccess" 
                    />
                </v-tab-item>
                <v-tab-item>
                    <change-password-form />
                </v-tab-item>
            </v-tabs>
        </v-col>
    </v-row>
</template>
<script>
import LoginForm from '@/components/auth/LoginForm.vue'
import RegisterForm from '@/components/auth/RegisterForm.vue'
import ChangePasswordForm from '@/components/auth/ChangePasswordForm.vue'
export default {
    name: 'Login',
    components: { LoginForm, RegisterForm, ChangePasswordForm },
    methods: {
        onSuccess() {
            return this.$router.push('/home').catch(() => {});
        },
        onBack() {
            return this.$router.push('/').catch(() => {});
        }
    }
}
</script>