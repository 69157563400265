import ApiHelper from '@/helpers/ApiHelper'
import Auth from '@/store/classes/auth'
import Crud from '@/store/classes/crud'

const auth = new Auth()
const crud = new Crud({
    module: 'user'
})

export default {
    namespaced: true,
    state: {
        ...auth.state,
        ...crud.state,
        filter: {
            active: null,
            role: null,
            name: null,
            login: null
        },
        roles: [
            {
                value: 'teacher',
                text: 'Педагог'
            },
            {
                value: 'student',
                text: 'Учащийся'
            }
        ]
    },
    mutations: {
        ...auth.mutations,
        ...crud.mutations
    },
    actions: {
        ...auth.actions,
        ...crud.actions,
        async upload(context, data) {
            return ApiHelper.post(`/api/user/upload`, data);
        }
    },
    getters: {
        ...auth.getters
    }
}