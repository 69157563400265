export default [{
        module: 'user',
        name: ['пользователи', 'пользователя']
    },
    {
        module: 'group',
        name: ['классы', 'классы']
    },
    {
        module: 'license',
        name: ['лицензии', 'лицензии']
    }
]