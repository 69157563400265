<template>
    <layout>
        <router-view :key="$route.path" />
    </layout>
</template>
<script>
import Layout from '@/components/template/Layout.vue'
export default {
    name: 'App',
    components: {
        Layout
    },
    created () {
        this.$store.dispatch('license/list', { pagination: 0 });
    }
};
</script>