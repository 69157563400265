// Code server
const CODE_SERVER = process.env.VUE_APP_CODE_SERVER_HOST;
const CODE_SERVER_API_KEY = process.env.VUE_APP_CODE_SERVER_API_KEY;

// Task server
const TASK_SERVER = process.env.VUE_APP_TASK_SERVER_HOST;
const TASK_SERVER_API_KEY = process.env.VUE_APP_TASK_SERVER_API_KEY;

export { 
	CODE_SERVER, CODE_SERVER_API_KEY,
	TASK_SERVER, TASK_SERVER_API_KEY,
}