import Login from '@/views/Login.vue'
import Error404 from '@/views/Error404.vue'
import DashboardHome from '@/views/DashboardHome.vue'

import crud from './crud'
import breadcrumbs from './breadcrumbs'

export default [{
        path: '/',
        name: 'Home',
        component: () => import('@/views/Landing.vue'),
        meta: {
            layout: 'landing'
        }
    }, 
    {
        path: "/demo/:subject+/:grade+/:is_final+",
        name: 'Demo',
        component: () => import('@/views/Demo.vue'),
        meta: {
            title: 'Демо-работа',
            layout: 'landing'
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            layout: 'login'
        }
    }, {
        path: '/home',
        name: 'DashboardHome',
        component: DashboardHome,
        meta: {
            breadcrumbs: [
                {
                    text: 'Главная',
                    disabled: true
                }
            ]
        }
    },
    ..._.flatten(_.map(crud, (item) => {
        return [{
                path: `/${item.module}`,
                component: () => import(`@/views/${item.module}/Index.vue`),
                meta: {
                    breadcrumbs: breadcrumbs[item.module].index
                }
            },
            {
                path: `/${item.module}/create`,
                component: () => import(`@/views/${item.module}/Create.vue`),
                meta: {
                    breadcrumbs: breadcrumbs[item.module].create
                }
            },
            {
                path: `/${item.module}/update/:id`,
                component: () => import(`@/views/${item.module}/Update.vue`),
                meta: {
                    breadcrumbs: breadcrumbs[item.module].update
                }
            }
        ];
    })),
    {
        path: `/user/upload`,
        component: () => import(`@/views/user/Upload.vue`),
        meta: {
            breadcrumbs: [{
                text: 'Главная',
                disabled: true
            },
            {
                text: 'Преподаватели',
                disabled: false,
                href: `/user?role=teacher`,
            },
            {
                text: 'Учащиеся',
                disabled: false,
                href: `/user?role=student`,
            },
            {
                text: 'Загрузка пользователей',
                disabled: false,
                href: `/user/upload`,
            }]
        }
    },
    {
        path: '*',
        component: Error404,
        meta: {
            breadcrumbs: [{
                    text: 'Главная',
                    disabled: false,
                    href: '/'
                },
                {
                    text: 'Страница не найдена',
                    disabled: true
                }
            ]
        }
    }
]